import React, { useEffect, useState } from 'react';
import { geoPath, geoMercator } from 'd3-geo';

const GeoJsonSvg = ({ farmGeoJson ,h,w,scalew,scaleh }) => {
   const [randomColor, setRandomColor] = useState(generateRandomColor());

  useEffect(() => {
    setRandomColor(generateRandomColor());
  }, []);

  const geojson = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {},
        geometry: farmGeoJson.geometry, 
      },
    ],
  };

  const svgWidth = w;
  const svgHeight = h;
  var pathData = geoPath(geoMercator().fitSize([scalew, scaleh], geojson))(geojson);
 
  function generateRandomColor() {
    let color;
    do {
      // Generate a random number and convert to hex, then ensure it's 6 digits
      color = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;
    } while (isColorTooDark(color));
    return color;
  }

  function isColorTooDark(hex) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000; 
    return brightness < 128; 
  }

  return (
    <div>
      <svg width={svgWidth} height={svgHeight} fill="#FFFFFF00">
        <rect width={svgWidth} height={svgHeight} fill="transparent" />
        <path
          d={pathData}
          fillOpacity="0.5"
          stroke="#f9b64d"
          transform="translate(4, 4)"
        />
       
      </svg>
    </div>
  );
};

export default GeoJsonSvg;