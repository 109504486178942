import Toolbar from "@mui/material/Toolbar";
import EARTH_IMAGE from "../../public/images/earth.png"
import { Box, Typography, Grid, Breadcrumbs } from "@mui/material";

import { useWindowSize } from "@uidotdev/usehooks";
export default function BreadCrumbs({ text }) {
    const windowSize = useWindowSize();
    return (
        <div id="invs">
            <Grid container >
                <Grid
                    item
                    xs={12}
                    style={{ backgroundColor: "#11151b", height: "270px" }}
                >

                    <Box
                        style={{

                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                    >
                        <Typography
                            variant="h6"
                            component="div"
                            color={"white"}
                            mb={3}
                            mt={20}
                            style={{ fontWeight: "bold" }}
                        >
                            <strong>{text}</strong>
                        </Typography>
                    </Box>

                </Grid>
            </Grid>
        </div>
    );
}
