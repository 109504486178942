import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useWindowSize } from "@uidotdev/usehooks";
import ESS_LOGO from "../../public/images/ESS-06.png";
import { Link } from "react-scroll";
import RequestAccountModal from "../utils/RequestAccountModal";
import {useRouter} from "../routes/hooks";

const drawerWidth = 240;
// const navItems = [
//   "Home",
//   "About",
//   "Investors' showcase",
//   "SDG",
//   "Contact",
//   "Crop Monitoring",
// ];

const navItems = [
  { name: "Home", link: "home" },
  { name: "About", link: "about" },
  { name: "Investors' showcase", link: "/investors-showcase" },
  { name: "SDG", link: "sdg" },
  { name: "Contact", link: "contact" },
  { name: "Crop Monitoring", link: "/crop-monitoring" },
];



function CustomHeader(props) {
  const router = useRouter();
  const windowSize = useWindowSize();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);


  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleRequestAccountClick = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img src={ESS_LOGO} width={"150px"}  />
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={1} disablePadding>
            <ListItemButton>
              {item.name === "Request Account" ? (
                <Link
                  key={item.name}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  style={{ textAlign: "center" }}
                >
                  <Button style={{ color: "black" }}>{item.name}</Button>
                </Link>
              ) : item.name === "Crop Monitoring" ? (
                <Link
                  key={item}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <Button
                    style={{
                      background: "#877849",
                      borderRadius: "10px",
                      boxShadow: "none",
                      color: "#fff",
                      display: "block",
                      fontSize: "13px",
                      fontWeight: 600,
                      margin: 0,
                      padding: "9px 8px",
                      textAlign: "center",
                      width: "100%",
                    }}
                    onClick={() => router.push('/registration')}
                  >        
                    <b  style={{ 
                      padding: "9px 8px" 
                    }}>{item.name}</b>
                  </Button>
                </Link>
              ) : (
                item.name === "Investors' showcase" ?<>
                
                <Link
                   key={item.name}
                   spy={true}
                   smooth={true}
                   offset={-70}
                   duration={500}
                   style={{ textAlign: "center", width: "100%" }}
                >
                 <Button style={{ color: "black" }} onClick={() =>console.log("HELO")} ><b>{item.name}</b></Button>
                </Link>
                
                </>:<>
                
                <Link
                  key={item.name}
                  to={item.link.toLowerCase()}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={handleDrawerToggle}
                  style={{ textAlign: "center" }}
                >
                   
                     <Button style={{ color: "black" }}   ><b>{item.name}</b></Button>
                  
                  
                </Link>
                
                </>
               
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar component="nav" style={{ backgroundColor: "#181f26" }}>
          <Toolbar
            disableGutters
            sx={{
              margin: {
                sx: 0,
                sm: "0 auto",
              },
              width:
                windowSize.width < 992
                  ? "100%"
                  : windowSize.width < 1025
                  ? "85%"
                  : windowSize.width < 1960
                  ? "65%"
                  : "50%",
            }}
          >
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, ml: 2, color: "white", display: { sm: "none" } }}
            >
              <MenuIcon />
              <img src={ESS_LOGO} width={"150px"} />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ display: { xs: "none", sm: "block" }, flexGrow: 1 }}
            >
              {/* <Link to={"/home"}>
              
              <img src={ESS_LOGO} width={"250px"} />
              
              </Link> */}

           
              <img src={ESS_LOGO} width="250px"  style={{cursor:"pointer"}} alt="ESS Logo"   onClick = {() => router.push('/home')} />
           

             
            </Typography>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item) =>
                item.name === "Request Account" ? (
                  <Link
                    key={item}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    style={{ textAlign: "center" }}
                  >
                    <Button style={{ color: "white" }}>{item.name}</Button>
                  </Link>
                ) : item.name === "Crop Monitoring" ? (
                  <Link
                    key={item.name}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    style={{ textAlign: "center" }}
                  >
                    <Button
                      style={{
                        background: "#fff",
                        borderRadius: "8px",
                        boxShadow: "none",
                        color: "#877849",
                        fontSize: "13px",
                        fontWeight: 400,
                        margin: 0,
                        padding: "10px 10px",
                        textAlign: "center",
                        margin:'10px'
                      }}
                      onClick = {() => router.push('/registration')}
                    >
                    <b style={{paddingLeft:'10px',paddingRight:'10px'}}>{item.name}</b>
                    </Button>
                  </Link>
                ) :  (
                  item.name === "Investors' showcase" ?<>
                  
                  <Link
                   key={item.name}
                    spy={true}
                   smooth={true}
                   offset={-70}
                   duration={500}
                   style={{ textAlign: "center"}}
                  >
                   <Button style={{ color: "white" }} onClick={() => router.push(item.link)} ><b>{item.name}</b></Button>
                  </Link>
                  
                  </>:<>
                  
                  <Link
                  key={item.name}
                  to={item.link.toLowerCase()}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  style={{ textAlign: "center"}}
                >
                  <Button style={{ color: "white",margin:'10px' }}><b>{item.name}</b></Button>
                </Link>
                  
                  </>
                 
                )
              )}
            </Box>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
      
    </>
  );
}

CustomHeader.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default CustomHeader;
