import { Box } from "@mui/material";
import { useWindowSize } from "@uidotdev/usehooks";
import { ASSET_IMAGE } from "../utils/constants";
export default function OuterCard({imageURL}){
 
    const windowSize = useWindowSize();
    return(

        <Box
        style={{
          borderRadius: "20px",
          backgroundImage:
            `url("${ASSET_IMAGE+"/"+imageURL}")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: windowSize.width < 1950 ? "70vh" : "45vh",
        }}
      />
    )
}