import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";
import { Button, Divider, Grow, Tooltip } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { ClearIcon } from "@mui/x-date-pickers";
import moment from "moment";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { GridLoader } from "react-spinners";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip as TP,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Oval } from "react-loader-spinner";
import Alert from "@mui/material/Alert";
import "./analytics.css";
import dayjs from 'dayjs';
export default function WeatherAnalytics({
  fieldData,
  isVisible,
  dateStart,
  dateEnd,
  center,
  onCloseClick,
}) {
  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTable: {
          styleOverrides: {
            root: {
              backgroundColor: "#28282b",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              backgroundColor: "#28282b",
              color: "white",
              fontWeight: 700,
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              color: "white",
              backgroundColor: "#28282b",
            },
          },
        },
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: "#28282b",
              color: "white",
            },
          },
        },
        MUIDataTablePagination: {
          styleOverrides: {
            root: {
              backgroundColor: "#28282b",
              color: "white",
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              color: "white",
            },
          },
        },
      },
    });

  const columns = [
    "Date",
    "ETo",
    "ALT",
    "HUMIDITY",
    "RAINFAILL",
    "SUNSHINE",
    "TEMPERATURE",
    "WIND",
  ];
  if (fieldData !== null) {
    columns.splice(1, 0, "WeatherAnalytics");
  }
  const [data, setData] = React.useState([]);
  const [graphData, setGraphData] = React.useState([]);
  const [weatherGraphData, setWeatherGraphData] = React.useState([]);
  const [showLoader, setShowLoader] = React.useState(false);
  const [sowingDate, setSowingDate] =  React.useState(dayjs());

  const _onCloseClick = async () => {
     
    onCloseClick();

  };
  const handleSowingDateChange = (date) => {

    setSowingDate(date);
   
  };

  function getDates(startDate, endDate) {
    const dates = [];
    let currentDate = startDate;
    const addDays = function (days) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  }
  React.useEffect(() => {
    var day1 = moment(dateStart);
    var day2 = moment(dateEnd);
    var result = [moment({ ...day2 })];
    while (day1.date() != day2.date()) {
      day2.add(1, "day");
      result.push(moment({ ...day2 }));
    }

    
  //  if(sowingDate == null){

  //  }else{
  //   fetchData();
  //  }
 
  //fetchData() 

  const MygraphData = data.map(item => ({
    date: item[0],
    ETo: item[1],
    Rainfall: parseFloat(item[4])
  }));
  setGraphData(MygraphData)
  console.log(MygraphData);

  }, [data]);
  
  const fetchData = async () => {
 
    try {
      const dates = getDates(new Date(sowingDate), new Date(dateEnd));
      console.log(dates)
      const resultArray = await Promise.all(
        dates.map(async (date) => {
          const postData = {
            lat: center.lat,
            lon: center.lng,
            date: moment(date).format("YYYY-MM-DD"),
          };
          const response = await axios.post(
            "https://escan-systems.com/api/cwrFetchData",
            postData
          );
          const responseData = response.data;
          //console.log("RESPONSe", responseData)
          const formattedObject = [
            responseData.Date,
            responseData.data,
            responseData.weatherParams.ALT,
            responseData.weatherParams.HUM,
            responseData.weatherParams.RAINFALL,
            responseData.weatherParams.SUNSHINE,
            responseData.weatherParams.TEMP,
            responseData.weatherParams.WIND,
          ];
          return formattedObject;
        })

        //   result.map(async (x) => {
        //     console.log("DATE",x.format("YYYY-MM-DD"))
        //   })
      
      );

      let graphDataArray = [];
      let weatherGraphDataArray = [];
      let allDates = [];
      let allEtoValues = [];
      resultArray.map((arr) => {
        const [date, eto, alt, hum, rainfall, sunshine, temp, wind] = arr;
        const newObj = {
          ETo: eto,
          date: date
            ? new Date(date).toLocaleDateString("en-US", {
              day: "numeric",
              month: "short",
            })
            : "",
          sortDate: date
            ? new Date(date).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })
            : "",
        };
        graphDataArray.push(newObj);
        const newObjForWeather = {
          Alt: alt,
          Humidity: hum,
          Rainfall: rainfall,
          Sunshine: sunshine,
          Temperature: temp,
          Wind: wind,
          date: date
            ? new Date(date).toLocaleDateString("en-US", {
              day: "numeric",
              month: "short",
            })
            : "",
          sortDate: date
            ? new Date(date).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })
            : "",
        };
        weatherGraphDataArray.push(newObjForWeather);
        allDates.push(date);
        allEtoValues.push(eto);
      });

      if (fieldData !== null) {
        const requestData = {
          allDates,
          fieldData,
          allEtoValues,
        };
        axios
          .post("https://escan-systems.com/api/cwrWeatherAndGraphData", requestData)
          .then((response) => {
            resultArray.map((singleArray, index) => {
              singleArray.splice(1, 0, response.data[index]);
            })
            setWeatherGraphData(weatherGraphDataArray);
            setGraphData(graphDataArray);
            setData(resultArray);
            setShowLoader(false);
          })
          .catch((error) => {
            console.error("Error:", error);
            setShowLoader(false);
          });
      } else {
        setWeatherGraphData(weatherGraphDataArray);
        setGraphData(graphDataArray);
        setData(resultArray);
        setShowLoader(false);
      }

  

    } catch (error) { 
      console.error("Error:", error);
    }

   // console.log(data)
   
  };
  return (
    <>
      <Grow in={isVisible} style={{ transformOrigin: "0 0 0" }}>
        <Card
          sx={{
            backgroundColor: "#28282B",
            position: "absolute",
            top: "30px",
            width: "100%",
            zIndex: 9999999999,
            height: ["220vh", "220vh", "150vh", "150vh", "130vh"],
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#28282B",
            }}
          >
            {/* <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1, padding: 1 }}> </Box> */}

            {/* <CardContent sx style={{ alignItems: 'center', alignContent: 'center', paddingBottom: 0, paddingLeft: 0 }}  >

            <Typography variant="subtitle1" color="white" component="div"
              style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {"Crop Water Requirements "}
            </Typography>
          </CardContent> */}

            <CardContent
              sx={{
                alignItems: "center",
                alignContent: "center",
                paddingBottom: 0,
                paddingLeft: 0,
              }}
            >
              <IconButton
                style={{ position: "absolute", top: "10px", right: "5px" }}
                onClick={_onCloseClick}
              >
                <ClearIcon color="white" style={{ color: "white" }} />
              </IconButton>

              <Typography
                variant="subtitle1"
                color="white"
                component="div"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                {"Weather Analytics"}
              </Typography>
            </CardContent>
          </Box>
          {showLoader ? (
            <Box
              style={{
                justifyContent: "start",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Oval
                visible={true}
                height="50"
                radius="50"
                width="50"
                color="#fff"
                secondaryColor="#cccc"
                ariaLabel="revolving-dot-loading"
                wrapperStyle={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 99999,
                }}
                wrapperClass=""
              />
            </Box>
          ) : (
            <Box
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
              }}
            >
             
             {/* 
              
              {fieldData === null && (
                <Alert
                  style={{
                    marginLeft: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "50%",
                  }}
                  severity="warning"
                >
                  Please select sowing date
                </Alert>



              )} 
               
              */}

              <ThemeProvider theme={getMuiTheme()}>

                  <Box
                    sx={{ 
                      display: "flex", 
                      flexDirection: "row", 
                      alignItems: "center", 
                      marginLeft: "10px",
                     
                    }}
                  >

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          fullWidth
                          label="Please Select Sowing Date"
                          value={sowingDate}
                          onChange={handleSowingDateChange} 
                          sx={{
                            width: "100%",
                            backgroundColor: 'black',
                            "& .MuiInputLabel-root": {
                              color: "white",
                            },
                            "& .MuiInputBase-root": {
                              color: "white",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgb(135, 120, 73)",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgb(135, 120, 73)",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "rgb(135, 120, 73)",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "white",
                            } 
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>

                    <Button
                      onClick={() => {
                        fetchData()
                      }}
                      variant="contained"
                      style={{
                        padding: "10px",
                        marginTop: 12,
                        marginLeft:10,
                        border: "none",
                        background: '#f9b64d',
                        color: 'white',
                        fontWeight: 'bold',
                      }}
                    >

                      Display analytics

                    </Button>
                  </Box>

                <MUIDataTable
                  style={{ marginTop: "-0px" }}
                  data={data}
                  columns={columns}
                  options={{
                    selectableRows: false,
                    responsive: "standard",
                    rowsPerPage: 5,
                    rowsPerPageOptions: [],
                    print: false,
                    filter: false,
                    viewColumns: false,
                  }}
                />

              </ThemeProvider>
              
              <Box
                sx={{
                  marginTop: "100px",
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: ["column", null, "row"],
                }}
              >
                  <ResponsiveContainer
                    width="100%"
                    height={400}
                    style={{ display: "flex" }}
                  >
                    {graphData.length > 0 && (
                      <LineChart
                        width={750}
                        height={500}
                        data={graphData}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                        style={{ color: "white" }}
                      >
                       
                        <TP label="INPUT" />
                       
                        <Legend />

                        <XAxis dataKey="date" />

                        <YAxis yAxisId="left" />

                        <YAxis yAxisId="right" orientation="right" />

                         <Line
                          type="monotone"
                          dataKey="ETo"
                          stroke="#00e676"
                          yAxisId="left"
                          dot={false}
                          activeDot={{ r: 5 }}
                        />

                      
                        <Line
                          type="monotone"
                          dataKey="Rainfall" 
                          stroke="#ffe28a" 
                          yAxisId="right"
                          dot={false}
                          activeDot={{ r: 5 }}
                        />
                      </LineChart>
                    )}
                  </ResponsiveContainer>


                {/* <ResponsiveContainer
                  width="100%"
                  height={400}
                  style={{ display: "flex" }}
                >
                  {graphData.length > 0 && (
                    <LineChart
                      width={750}
                      height={500}
                      data={graphData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      style={{ color: "white" }}
                    >
                      <TP label="INPUT" />
                      <Legend />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Line
                        type="monotone"
                        dataKey="ETo"
                        stroke="#00e676"
                        dot={false}
                        activeDot={{ r: 5 }}
                      />
                    </LineChart>
                  )}
                </ResponsiveContainer> */}
            
              </Box>
            </Box>
          )}
        </Card>
      </Grow>
    </>
  );
}
