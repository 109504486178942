import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";
import { Button, Chip, Divider, Grow, InputLabel, ListItem, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { ClearIcon } from "@mui/x-date-pickers";
import moment from "moment";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { GridLoader } from "react-spinners";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip as TP,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
} from "recharts";
import { Oval } from "react-loader-spinner";
import Alert from "@mui/material/Alert";
import "./analytics.css";
import dayjs from 'dayjs';

import { FormControl } from "@mui/material";
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    Avatar,
    ConversationList,
    Conversation,
    ConversationHeader,
    MessageSeparator,
    TypingIndicator,
  } from "@chatscope/chat-ui-kit-react";
import cropsArray from "./cropsArray";
import { CloseButton } from "react-bootstrap";
import { CloseOutlined } from "@mui/icons-material";

  
export default function BotView({
     
lat,lng,onClose
  
}) {

    const [userInput, setUserInput] = React.useState('');
    const handleConfirm = () => {
        
        setConfirmationMessage(`Year: ${selectedYear}, Crop: ${selectedCrop}`);
    };
    let myCrop= 0;
    let myYear = 0;
    let myResponse ="";
    const [selectedYear, setSelectedYear] = React.useState('');
    const [selectedCrop, setSelectedCrop] = React.useState('');
    const [confirmationMessage, setConfirmationMessage] = React.useState('');

    const [handleCase, setCase] =  React.useState(userInput); 
    const [botResponse, setBotResponse] =  React.useState(''); 
    const [selectedChip, setSelectedChip] =  React.useState(0); 
    const [selectedIndex, setSelectedIndex] =  React.useState("Wheat"); 
    const [cropOptions, setCropOptions] = React.useState([
        { key: 0, label: 'Wheat' },
        { key: 1, label: 'Rice' },
        { key: 2, label: 'Corn' },
        { key: 3, label: 'Barley' },
        { key: 4, label: 'Cotton' },
        { key: 5, label: 'Soyabean' },
        // Add other crops as needed
    ]);

    const currentYear = new Date().getFullYear();

    const years = Array.from({ length: currentYear - 2013 }, (_, index) => 2014 + index);
    const days = [7,14,21,28];

 

    const closeChat = async () =>{
      onClose();
    }
    const handleBotResponse = async (userCase,userCrop,userYear) => {
        setShowLoader(true)
        //console.log(selectedCrop,selectedYear,myCrop,myYear)
       if(userCase == 1){
        let data = JSON.stringify({
            "action": "get_yield",
            "crop": myCrop,
            "year": myYear,
            "lat":lat,
            "lng":lng
          });
          
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://escan-systems.com/api/query-response',
            headers: { 
              'Content-Type': 'application/json', 
              'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzI3MTAxMzg4fQ.bj6dXIuNULi_jNMuXoC_bw-1un7g-3pBddrqm3wr4r8'
            },
            data : data
          };
          
          axios.request(config)
          .then((response) => {
          

            setBotResponse(response.data)
            myResponse = response.data
            setBotResponse(response.data)
            setShowLoader(false)
            handleUserMessage('Please proceed for calculations')
            console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            console.log(error);
          });
       }else if(userCase == 2){

        let data = JSON.stringify({
            "action": "get_monitor_plan",
            "crop": myCrop,
            "year": myYear,
            "lat":lat,
            "lng":lng
          });
         // console.log(data)
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://escan-systems.com/api/query-response',
            headers: { 
              'Content-Type': 'application/json', 
              'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzI3MTAxMzg4fQ.bj6dXIuNULi_jNMuXoC_bw-1un7g-3pBddrqm3wr4r8'
            },
            data : data
          };
          
        await axios.request(config)
          .then((response) => {
            let cleanedString = response.data.replace(/```html|\```/g, '').trim();

            console.log(cleanedString);

            setBotResponse(cleanedString)
            myResponse = cleanedString
           
           //console.log(JSON.stringify(response.data));
            setShowLoader(false)
            handleUserMessage('Please proceed for calculations')
          })
          .catch((error) => {
            console.log(error);
          });

       }else if(userCase == 3){

        let data = JSON.stringify({
            "action": "get_weather_prediction",
            "crop": myCrop,
            "year": myYear,
            "days": myYear,
            "lat":lat,
            "lng":lng
          });
        
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://escan-systems.com/api/query-response',
            headers: { 
              'Content-Type': 'application/json', 
              'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzI3MTAxMzg4fQ.bj6dXIuNULi_jNMuXoC_bw-1un7g-3pBddrqm3wr4r8'
            },
            data : data
          };
          
        await axios.request(config)
          .then((response) => {

            let cleanedString = response.data.replace(/```html|\```/g, '').trim();

            console.log(cleanedString);

            setBotResponse(cleanedString)
            myResponse = cleanedString
           
           //console.log(JSON.stringify(response.data));
            setShowLoader(false)
            handleUserMessage('Please proceed for calculations')
          })
          .catch((error) => {
            console.log(error);
          });

       }else if(userCase == 4){

        let data = JSON.stringify({
            "action":"get_agriculture_Solutions",
            "crop": myCrop,
            "year": myYear,
            "days": myYear,
            "lat":lat,
            "lng":lng
          });
        
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://escan-systems.com/api/query-response',
            headers: { 
              'Content-Type': 'application/json', 
              'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzI3MTAxMzg4fQ.bj6dXIuNULi_jNMuXoC_bw-1un7g-3pBddrqm3wr4r8'
            },
            data : data
          };
          
        await axios.request(config)
          .then((response) => {

            let cleanedString = response.data.replace(/```html|\```/g, '').trim();

            console.log(cleanedString);

            setBotResponse(cleanedString)
            myResponse = cleanedString
           
           //console.log(JSON.stringify(response.data));
            setShowLoader(false)
            handleUserMessage('Please proceed for calculations')
          })
          .catch((error) => {
            console.log(error);
          });

       }else if(userCase == 5){
        let data = JSON.stringify({
          "action":"NDVI",
          "crop": myCrop,
          "year": myYear,
          "days": myYear,
          "lat":lat,
          "lng":lng
        });
      
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://escan-systems.com/api/query-response',
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzI3MTAxMzg4fQ.bj6dXIuNULi_jNMuXoC_bw-1un7g-3pBddrqm3wr4r8'
          },
          data : data
        };
        
      await axios.request(config)
        .then((response) => {

          let cleanedString = response.data.replace(/```html|\```/g, '').trim();

          console.log(cleanedString);

          setBotResponse(cleanedString)
          myResponse = cleanedString
         
         //console.log(JSON.stringify(response.data));
          setShowLoader(false)
          handleUserMessage('Please proceed for calculations')
        })
        .catch((error) => {
          console.log(error);
        });
       }


      
          
    }


    const handleUserMessage = (userInput) => {
        let responseMessage = '';

        switch (userInput) {
            case '1': 
                responseMessage = (
                    <Message.CustomContent>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2, // Space between elements 
                                padding: 2,
                                borderRadius: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                position: 'relative', // Ensure positioning context for the dropdown
                                zIndex: 1000, // Set z-index for the content box so it stays above other elements
                            }}
                        >
                             <InputLabel htmlFor="grouped-native-select" style={{ color: "#fff" }}> Select Crops from below</InputLabel>
                            <Paper
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'nowrap',
                                    listStyle: 'none', flexDirection: 'row', backgroundColor: '#000', color: '#fff', boxShadow: 'none',
                                    p: 0.5,
                                    m: 0,
                                  }}
                                  component="ul"
                                >
                                  {cropOptions.map((data) => {


                                    return (
                                      <ListItem key={data.key}>
                                        <Chip
                                          variant="outlined"
                                          label={data.label}
                                          size="large"
                                          style={{
                                            color: 'white',
                                            borderColor: selectedChip === data ? 'white' : 'white',
                                          }}
                                          onClick={async () => {
                                            setCase(1)
                                            setSelectedCrop(data.label)
                                            myCrop = data.label;
                                            console.log(data.key)
                                            //setCase('crop_selected')
                                            handleUserMessage('Please proceed for calculations')
                                          
                                            await handleBotResponse(2,selectedCrop,selectedYear)
                                            
                                            //handleUserMessage('please select year')
                                            // setSelectedChip(data.key)
                                           // setShowPreviewLoader(true)
                                           

                                          }}
                                        />
                                      </ListItem>
                                    );
                                  })}
                                </Paper>

                          
                        </Box>
                    </Message.CustomContent>

                );
                break;

            case 'please select year':
                responseMessage = (
                    <Message.CustomContent>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2, // Space between elements 
                                padding: 2,
                                borderRadius: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                position: 'relative', // Ensure positioning context for the dropdown
                                zIndex: 1000, // Set z-index for the content box so it stays above other elements
                            }}
                        >
                         
                         <InputLabel htmlFor="grouped-native-select" style={{ color: "#fff" }}> Select Year </InputLabel>

                            <Paper
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'nowrap',
                                    listStyle: 'none', flexDirection: 'row', backgroundColor: '#000', color: '#fff', boxShadow: 'none',
                                    p: 0.5,
                                    m: 0,
                                  }}
                                  component="ul"
                                >
                                  {years.map((data) => {


                                    return (
                                      <ListItem key={data}>
                                        <Chip
                                          variant="outlined"
                                          label={data}
                                          size="large"
                                          style={{
                                            color: 'white',
                                            borderColor: selectedChip === data ? '#f9b64d' : 'white',
                                          }}
                                          onClick={async () => {
                                          
                                            myYear =  data
                                            setSelectedYear(data) 
                                            handleUserMessage('Please proceed for calculations')
                                          
                                            await handleBotResponse(1,selectedCrop,selectedYear)
                                            
                                            //setSelectedChip(data)
                                           // setShowPreviewLoader(true)
                                           

                                          }}
                                        />
                                      </ListItem>
                                    );
                                  })}
                                </Paper> 
                        </Box>
                    </Message.CustomContent>

                );
                break;  
            
            case 'Please proceed for calculations':
              responseMessage = myResponse;
              break;
           
            case '2':
                responseMessage = (
                    <Message.CustomContent>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2, // Space between elements 
                                padding: 2,
                                borderRadius: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                position: 'relative', // Ensure positioning context for the dropdown
                                zIndex: 1000, // Set z-index for the content box so it stays above other elements
                            }}
                        >
                             <InputLabel htmlFor="grouped-native-select" style={{ color: "#fff" }}> Select Crops for Analysis </InputLabel>

                            {/* Crops Input */}
                            <Paper
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'nowrap',
                                    listStyle: 'none', flexDirection: 'row', backgroundColor: '#000', color: '#fff', boxShadow: 'none',
                                    p: 0.5,
                                    m: 0,
                                  }}
                                  component="ul"
                                >

                               

                                  {cropOptions.map((data) => {


                                    return (
                                      <ListItem key={data.key}>
                                        <Chip
                                          variant="outlined"
                                          label={data.label}
                                          size="large"
                                          style={{
                                            color: 'white',
                                            borderColor: selectedChip === data ? 'white' : 'white',
                                          }}
                                          onClick={async () => {
                                            setCase(2) 
                                            myCrop = data.label;
                                            console.log(data.key)
                                            handleBotResponse(5)
                                           

                                          }}
                                        />
                                      </ListItem>
                                    );
                                  })}
                                </Paper>

                          
                        </Box>
                    </Message.CustomContent>

                );
             break;
            
            case 'please select number of days':
                responseMessage = (
                    <Message.CustomContent>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2, // Space between elements 
                                padding: 2,
                                borderRadius: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                position: 'relative', // Ensure positioning context for the dropdown
                                zIndex: 1000, // Set z-index for the content box so it stays above other elements
                            }}
                        >
                        <InputLabel htmlFor="grouped-native-select" style={{ color: "#fff" }}> Select Number Days </InputLabel>
                            {/* Year Selection */}

                            <Paper
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'nowrap',
                                    listStyle: 'none', flexDirection: 'row', backgroundColor: '#000', color: '#fff', boxShadow: 'none',
                                    p: 0.5,
                                    m: 0,
                                }}
                                component="ul"
                            >
                                {days.map((data) => {


                                    return (
                                        <ListItem key={data}>
                                            <Chip
                                                variant="outlined"
                                                label={data}
                                                size="large"
                                                style={{
                                                    color: 'white',
                                                    borderColor: selectedChip === data ? '#f9b64d' : 'white',
                                                }}
                                                onClick={async () => {

                                                    myYear = data
                                                    setSelectedYear(data)
                                                    handleUserMessage('Please proceed for calculations')

                                                    await handleBotResponse(3, selectedCrop, selectedYear)

                                                    //setSelectedChip(data)
                                                    // setShowPreviewLoader(true)


                                                }}
                                            />
                                        </ListItem>
                                    );
                                })}
                            </Paper>

                        </Box>
                    </Message.CustomContent>

                );
                break;  

            case '3':
                responseMessage = (
                    <Message.CustomContent>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2, // Space between elements 
                                padding: 2,
                                borderRadius: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                position: 'relative', // Ensure positioning context for the dropdown
                                zIndex: 1000, // Set z-index for the content box so it stays above other elements
                            }}
                        >
                             <InputLabel htmlFor="grouped-native-select" style={{ color: "#fff" }}> Select Crops </InputLabel>
                             
                            {/* Crops Input */}
                            <Paper
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'nowrap',
                                    listStyle: 'none', flexDirection: 'row', backgroundColor: '#000', color: '#fff', boxShadow: 'none',
                                    p: 0.5,
                                    m: 0,
                                  }}
                                  component="ul"
                                >

                               

                                  {cropOptions.map((data) => {


                                    return (
                                      <ListItem key={data.key}>
                                        <Chip
                                          variant="outlined"
                                          label={data.label}
                                          size="large"
                                          style={{
                                            color: 'white',
                                            borderColor: selectedChip === data ? 'white' : 'white',
                                          }}
                                          onClick={async () => {
                                            setCase(2) 
                                            myCrop = data.label;
                                            console.log(data.key)
                                            handleUserMessage('please select number of days')
                                            //handleBotResponse(2)
                                           

                                          }}
                                        />
                                      </ListItem>
                                    );
                                  })}
                                </Paper>

                          
                        </Box>
                    </Message.CustomContent>

                );
            break;
           
            case '4':
                  handleBotResponse(4,selectedCrop,selectedYear)
            break;

            case '0':
                responseMessage = 'Reply with the option number below to get assistance in decision-making! \n 1. Get Monitoring Plan for your Crop \n 2. Crop Growth Analysis '
            break;

            default:
                responseMessage = 'Invalid option. Please Reply with the option number below to get assistance in decision-making! \n 1. Get Monitoring Plan for your Crop \n 2. Crop Growth Analysis ';
            break;
        }


        setMessages([
            ...messages,
            {
                direction: 'outgoing',
                message: userInput,
                position: 'first',
                sender: 'User',
                sentTime: '1 sec ago',
            },
            {
                direction: 'incoming',
                message: responseMessage,
                position: 'single',
                sender: 'Green Bot',
                sentTime: '1 sec ago',
            },
        ]);

        localStorage.setItem("viewable",JSON.stringify([
            ...messages,
            {
                direction: 'outgoing',
                message: userInput,
                position: 'first',
                sender: 'User',
                sentTime: '1 sec ago',
            },
            {
                direction: 'incoming',
                message: responseMessage,
                position: 'single',
                sender: 'Green Bot',
                sentTime: '1 sec ago',
            },
        ]))
    };


    const myMessage = JSON.stringify([
        {
          direction: 'incoming',
          message: 'Unlock the power of our Green BOT — a cutting-edge, intelligent assistant designed to apply advanced algorithms, helping you make smarter, data-driven decisions for your field. Reply with the option number below to get assistance in decision-making!',
          position: 'single',
          sender: 'Green Bot',
          sentTime: '1 sec ago',
        },
        // {
        //   direction: 'incoming',
        //   message: '1. Check Crop Yield for Past 10 years',
        //   position: 'single',
        //   sender: 'Green Bot',
        //   sentTime: '1 sec ago',
        // },
        {
          direction: 'incoming',
          message: '1. Get Monitoring Plan for your Crop',
          position: 'single',
          sender: 'Green Bot',
          sentTime: '1 sec ago',
        },
        // {
        //   direction: 'incoming',
        //   message: '3. Get Weather Forecasts For your Crop',
        //   position: 'single',
        //   sender: 'Green Bot',
        //   sentTime: '1 sec ago',
        // },
        {
          direction: 'incoming',
          message: '2. Crop Growth Analysis',
          position: 'single',
          sender: 'Green Bot',
          sentTime: '1 sec ago',
        },
      ]);
      
      
      if (!localStorage.getItem("viewable")) {
       
        localStorage.setItem("viewable", myMessage);

      }

    const [messages, setMessages] = React.useState(JSON.parse(localStorage.getItem("viewable")));
 

    const getMuiTheme = () =>
        createTheme({
            components: {
                MuiTable: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "#28282b",
                        },
                    },
                },
                MUIDataTableHeadCell: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "#28282b",
                            color: "white",
                            fontWeight: 700,
                        },
                    },
                },
                MUIDataTableBodyCell: {
                    styleOverrides: {
                        root: {
                            color: "white",
                            backgroundColor: "#28282b",
                        },
                    },
                },
                MUIDataTableToolbar: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "#28282b",
                            color: "white",
                        },
                    },
                },
                MUIDataTablePagination: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "#28282b",
                            color: "white",
                        },
                    },
                },
                MuiIconButton: {
                    styleOverrides: {
                        root: {
                            color: "white",
                        },
                    },
                },
            },
        });

    const columns = [
        "Date",
        "ETo",
        "ALT",
        "HUMIDITY",
        "RAINFAILL",
        "SUNSHINE",
        "TEMPERATURE",
        "WIND",
    ];
  
    const [data, setData] = React.useState([]);
    const [graphData, setGraphData] = React.useState([]);
    const [weatherGraphData, setWeatherGraphData] = React.useState([]);
    const [showLoader, setShowLoader] = React.useState(false);
    const [sowingDate, setSowingDate] = React.useState(dayjs());

  
    React.useEffect(() => {

      
  
    }, [botResponse]); 


    return (
        <>
            
                <MainContainer responsive>

                    <ChatContainer style={{
                        display: "flex",
                        flexBasis: "auto",
                        width: "100%",
                        maxWidth: "100%"
                    }}>
                        <ConversationHeader>
                            <ConversationHeader.Back />
                            <Avatar
                                name="ESS Green BOT"
                                src="https://dmenjsnbha54b.cloudfront.net/images/bot.png"
                                status="available"
                            />
                            <ConversationHeader.Content userName="ESS Green Bot" info="Active Now | Memory mode is off, please keep window open while getting response. Responses can only be saved after they are displayed on the screen." />
                            <ConversationHeader.Actions>
                              <CloseOutlined title="Close Conversation" 
                              onClick={closeChat}
                              style={{ color: '#fff', marginLeft: 1, marginBottom: 1, padding: 1,cursor:'pointer' }} />
                            </ConversationHeader.Actions>
                        </ConversationHeader>

                        
                        {/*   typingIndicator={<TypingIndicator content="Green Bot is typing" />}  */}
                    <MessageList
                        typingIndicator={showLoader ? <TypingIndicator content="Green Bot is typing" /> : null}
                        scrollBehavior="auto"
                        
                    >
                            <MessageSeparator content={moment().format('dddd, DD MMMM YYYY')} />

                        {messages.map((msg, index) => (

                            <>

                                {

                                    <Message key={index} model={msg}>
                                        {msg.direction === 'incoming' && (
                                            <Avatar
                                                name={msg.sender}
                                                src="https://dmenjsnbha54b.cloudfront.net/images/bot.png"
                                            />
                                        )}
                                    </Message>

                                }

                            </>
 
                        ))}

                            {/* <Message
                    model={{
                      direction: 'incoming',
                      message: 'Unlock the power of our Green BOT — a cutting-edge, intelligent assistant designed to apply advanced algorithms, helping you make smarter, data-driven decisions for your field. Reply with option number the options below and to get assistant in decision-making!',
                      position: 'single',
                      sender: 'Green Bot',
                      sentTime: '1 sec ago'
                    }}
                  >
                    <Avatar
                      name="Green Bot"
                      src="https://dmenjsnbha54b.cloudfront.net/images/bot.png"
                    />
                  </Message>


                  <Message
                  avatarSpacer
                    model={{
                      direction: 'incoming',
                      message: '1. Check Crop Yield for Past 10 years',
                      position: 'single',
                      sender: 'Green Bot',
                      sentTime: '1 sec ago'
                    }}
                  >
                     
                  </Message>

                  <Message
                  avatarSpacer
                    model={{
                      direction: 'incoming',
                      message: '2. Get Monitoring Plan for your Crop',
                      position: 'single',
                      sender: 'Green Bot',
                      sentTime: '1 sec ago'
                    }}
                  >
                    
                  </Message>

 

                  <Message
                  avatarSpacer
                    model={{
                      direction: 'incoming',
                      message: '3. Get Weather Forecasts For your Crop',
                      position: 'single',
                      sender: 'Green Bot',
                      sentTime: '1 sec ago'
                    }}
                  >
                     
                  </Message>



                  <Message
                  avatarSpacer
                    model={{
                      direction: 'incoming',
                      message: '4. Get Optimal Solutions for Major Agriculture problems',
                      position: 'single',
                      sender: 'Green Bot',
                      sentTime: '1 sec ago'
                    }}
                  >
                     
                  </Message>

                  <Message
                    model={{
                      direction: 'outgoing',
                      message: '1',
                      position: 'first',
                      sender: 'User',
                      sentTime: '15 mins ago'
                    }}
                  >

                    <Avatar
                      name="Green Bot"
                      src="https://dmenjsnbha54b.cloudfront.net/images/bot.png"
                    />

                    </Message>
                    <Message
                  avatarSpacer
                    model={{
                      direction: 'incoming',
                      message: '4. Get Optimal Solutions for Major Agriculture problems',
                      position: 'single',
                      sender: 'Green Bot',
                      sentTime: '1 sec ago'
                    }}
                  >
                     
                  </Message> */}


                        </MessageList>
                        <MessageInput
                            placeholder="Type your query or type 0 and press enter for menu"
                            attachButton={false}
                            onSend={(e) => {
                                setUserInput(e)
                                handleUserMessage(e)
                            }}
                        />
                    </ChatContainer>
                </MainContainer>
            
        </>
    );
}
