import React, { useState } from "react";
import { Container, Grid, TextField, Button } from "@mui/material";
import axios from "axios";
import { Bounce, ToastContainer, toast } from "react-toastify";
import ForgotPasswordModal from "../utils/ForgotPasswordModal";
import { useAuth } from "../hooks/useAuth";
import { Oval } from "react-loader-spinner";
import { Typography } from "antd";
import RequestAccountModal from "../utils/RequestAccountModal";

const LoginCard = () => {
  const { loginAuth } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
  const [openRequestAccountModal, setOpenRequestAccountModal] =
  React.useState(false);
  const loginUser = async () => {
    setShowLoader(true);
    const endPoint = "https://escan-systems.com/api/signin";
    const data = { email, password };
    try {
      const response = await axios.post(endPoint, data);
      await loginAuth({ token: response.data.token });
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      toast.error("Invalid credentials", {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <>
      <Container style={{ width: "100%" }}>
        <Grid container spacing={3} mt={2}>
          <Grid item xs={12}>
            <TextField
              id="outlined-email-input"
              label="Email"
              placeholder="example@gmail.com *"
              fullWidth
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              autoComplete="off"
              

              sx={{
                input: {
                  color: 'white',
                  background: 'black',
                  borderRadius: '15px',
                },
                '& .MuiOutlinedInput-root': {
                   
                  '&.Mui-focused fieldset': {
                    borderColor: 'rgb(135 120 73)',  
                  },
                },  
              }}

              InputLabelProps={{
                style: { color: "white", fontSize: "15px" },
              }}
              InputProps={{ style: { borderRadius: "15px" } }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-password-input"
              label="Password"
              type="password"
              fullWidth
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              autoComplete="off"
              sx={{
                input: {
                  color: 'white',
                  background: 'black',
                  borderRadius: '15px',
                },
                '& .MuiOutlinedInput-root': {
                   
                  '&.Mui-focused fieldset': {
                    borderColor: 'rgb(135 120 73)',  
                  },
                },  
              }}

              InputLabelProps={{
                style: { color: "white", fontSize: "15px" },
              }}
              InputProps={{ style: { borderRadius: "15px" } }}
            />
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
            <Typography style={{color:"#877849",cursor:'pointer'}} onClick={() => setOpenRequestAccountModal(true)}>
              Request an account
            </Typography>
            <Typography style={{color:"#877849",cursor:'pointer'}} onClick={()=>setOpenForgotPasswordModal(true)}>
              Forgot Password?
            </Typography>
          </Grid>
          <Grid item xs={12} mb={3}>
            <Button
              disabled={showLoader}
              variant="contained"
              style={{
                background: "#877849",
                color: "white",
                marginTop: "10px",
                width: "100%",
                padding: "12px",
                borderRadius: "15px",
                fontWeight: "bold",
                fontSize: "15px",
                textTransform: "capitalize",
              }}
              onClick={loginUser}
            >
              Proceed
              <Oval
                visible={showLoader}
                height="20"
                radius="20"
                width="20"
                color="#fff"
                secondaryColor="#cccc"
                ariaLabel="revolving-dot-loading"
                wrapperStyle={{
                  marginLeft: 10,
                }}
                wrapperClass=""
              />
            </Button>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      <ForgotPasswordModal
        open={openForgotPasswordModal}
        setOpen={setOpenForgotPasswordModal}
      />
      <RequestAccountModal
        open={openRequestAccountModal}
        setOpen={setOpenRequestAccountModal}
      />
    </>
  );
};

export default LoginCard;
