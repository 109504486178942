import { useRoutes, Navigate } from "react-router-dom";
import { useAuth } from "./components/hooks/useAuth";
import MapView from "./components/advisory";
import Homepage from "./components/home/Homepage";
import Registration from "./components/registration/LoginPage";
import PrivacyPolicy from "./components/privacypolicy";
import { Helmet } from "react-helmet";
import InvestorsPage from "./components/Investors-View/InvestorsPage";
import TutorialComponent from "./components/how-to/TutorialComponent";
import Tutorial from "./components/how-to";
 

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/registration" />;
  }
  return children;
};

const AppRouter = () => {
  const { user } = useAuth();
  <Helmet>
    <meta property="og:title" content="Earth Scan System | One stop solutions for Earth problems" />
    <meta property="og:description" content="ESS aims to create a beneficial impact by leveraging geospatial data alongside tailored algorithms." />
    <meta property="og:image" content="https://escan-systems.com/static/media/ESS-06.ca2868adecf111629108.png" />
    <meta property="og:url" content="https://escan-systems.com/" />
    <meta property="og:type" content="website" />
   
  </Helmet>
  const routes = useRoutes([
    { path: `${process.env.PUBLIC_URL}`, element: <Homepage /> },
    { path: `${process.env.PUBLIC_URL}/investors-showcase`, element: <InvestorsPage /> },
    {
      path: `${process.env.PUBLIC_URL}/registration`,
      element: user ? <Navigate to="/monitor" /> : <Registration />,
    },
    {
      path: `/monitor`,
      element: (
        <ProtectedRoute>
          <MapView />
        </ProtectedRoute>
      ),
    },
    { path: "*", element: <Navigate to={`${process.env.PUBLIC_URL}`} /> },
    { path: '/privacy-policy', element: <PrivacyPolicy  /> },
    { path: '/how-to', element: <Tutorial  /> },
  ]);

  return routes;
};

export default AppRouter;
